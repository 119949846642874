define("ember-user-activity/services/scroll-activity", ["exports", "ember-user-activity/services/-private/fastboot-aware-event-manager", "@ember/runloop", "ember-user-activity/utils/get-scroll"], function (_exports, _fastbootAwareEventManager, _runloop, _getScroll) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  /*
   * Polling uses rAF and/or a setTimeout at 16ms, however rAF will run in the
   * microtask queue and might fire just after Ember's render step occurred.
   * By enforcing that the interval between a poll and the previous must be
   * below a reasonable number, we can be reasonably sure the main UI
   * thread didn't just do a lot of work.
   *
   * This number should be above the minimum polling period (16ms)
   */
  const MAX_POLL_PERIOD = 32;
  const SCROLL_EVENT_TYPE_VERTICAL = 'vertical';
  const SCROLL_EVENT_TYPE_HORIZONTAL = 'horizontal';
  const SCROLL_EVENT_TYPE_DIAGONAL = 'diagonal';
  class ScrollActivityService extends _fastbootAwareEventManager.default {
    constructor() {
      super(...arguments);
      _defineProperty(this, "_animationFrame", null);
      _defineProperty(this, "_subscribers", []);
      _defineProperty(this, "_lastCheckAt", new Date());
      if (this._isFastBoot) {
        return;
      }
      this.subscribe(document, document, () => {}, false);
      this._pollScroll();
    }
    subscribe(target, element, callback = () => {}, highPriority = true) {
      this._subscribers.push({
        target,
        element,
        callback,
        highPriority,
        scrollTop: null,
        scrollLeft: null
      });
    }
    unsubscribe(target) {
      let {
        _subscribers: subscribers
      } = this;
      for (let i = 0; i < subscribers.length; i++) {
        let subscriber = subscribers[i];
        if (subscriber.target === target) {
          subscribers.splice(i, 1);
          break;
        }
      }
    }
    _pollScroll() {
      if (this._isFastBoot) {
        return;
      }
      if (window.requestAnimationFrame) {
        this._animationFrame = requestAnimationFrame(() => this._checkScroll());
      } else {
        this._animationFrame = setTimeout(() => this._checkScroll(), 16);
      }
    }
    _checkScroll() {
      let {
        _subscribers: subscribers
      } = this;
      let now = new Date();
      if (subscribers.length) {
        if (this._hasScrolled(now)) {
          this.trigger('scroll');
          (0, _runloop.end)();
        }
      }
      this._lastCheckAt = now;
      this._pollScroll();
    }
    _updateScroll(subscriber) {
      subscriber.scrollTop = (0, _getScroll.default)(subscriber.element);
      subscriber.scrollLeft = (0, _getScroll.default)(subscriber.element, 'left');
    }
    _hasScrolled(now) {
      let {
        _subscribers: subscribers,
        _lastCheckAt: lastCheckAt
      } = this;
      let lowPriorityFrame = now - lastCheckAt < MAX_POLL_PERIOD;
      let hasScrolled = false;
      for (let i = 0; i < subscribers.length; i++) {
        let subscriber = subscribers[i];
        if (subscriber.highPriority || lowPriorityFrame) {
          let scrollTop = (0, _getScroll.default)(subscriber.element);
          let scrollLeft = (0, _getScroll.default)(subscriber.element, 'left');
          if (scrollTop !== subscriber.scrollTop && scrollLeft !== subscriber.scrollLeft) {
            hasScrolled = this._handleAllScrollChanged(subscriber, hasScrolled);
          } else if (scrollTop !== subscriber.scrollTop) {
            hasScrolled = this._handleScrollTopChanged(subscriber, hasScrolled);
          } else if (scrollLeft !== subscriber.scrollLeft) {
            hasScrolled = this._handleScrollLeftChanged(subscriber, hasScrolled);
          }
        }
      }
      return hasScrolled;
    }
    _handleAllScrollChanged(subscriber, hasScrolled) {
      // If the values are changing from an initial null state to first-time values, do not treat it like a change.
      if (subscriber.scrollTop !== null && subscriber.scrollLeft !== null) {
        if (!hasScrolled) {
          (0, _runloop.begin)();
          hasScrolled = true;
        }
        let scrollTop = (0, _getScroll.default)(subscriber.element);
        let scrollLeft = (0, _getScroll.default)(subscriber.element, 'left');
        subscriber.callback(scrollTop, subscriber.scrollTop, SCROLL_EVENT_TYPE_DIAGONAL, scrollLeft, subscriber.scrollLeft);
      }
      this._updateScroll(subscriber);
      return hasScrolled;
    }
    _handleScrollLeftChanged(subscriber, hasScrolled) {
      // If the value is changing from an initial null state to a first
      // time value, do not treat it like a change.
      if (subscriber.scrollLeft !== null) {
        if (!hasScrolled) {
          (0, _runloop.begin)();
          hasScrolled = true;
        }
        subscriber.callback((0, _getScroll.default)(subscriber.element, 'left'), subscriber.scrollLeft, SCROLL_EVENT_TYPE_HORIZONTAL);
      }
      this._updateScroll(subscriber);
      return hasScrolled;
    }
    _handleScrollTopChanged(subscriber, hasScrolled) {
      // If the value is changing from an initial null state to a first
      // time value, do not treat it like a change.
      if (subscriber.scrollTop !== null) {
        if (!hasScrolled) {
          (0, _runloop.begin)();
          hasScrolled = true;
        }
        subscriber.callback((0, _getScroll.default)(subscriber.element), subscriber.scrollTop, SCROLL_EVENT_TYPE_VERTICAL);
      }
      this._updateScroll(subscriber);
      return hasScrolled;
    }
    willDestroy() {
      if (this._isFastBoot) {
        return;
      }
      if (window.requestAnimationFrame) {
        cancelAnimationFrame(this._animationFrame);
      } else {
        clearTimeout(this._animationFrame);
      }
      this._subscribers.length = 0;
      super.willDestroy(...arguments);
    }
  }
  _exports.default = ScrollActivityService;
});