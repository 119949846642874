define("ember-user-activity/services/user-activity", ["exports", "ember-user-activity/services/-private/fastboot-aware-event-manager", "ember", "@ember/array", "@ember/runloop", "@ember/service", "ember-user-activity/utils/storage-available"], function (_exports, _fastbootAwareEventManager, _ember, _array, _runloop, _service, _storageAvailable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class, _descriptor;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let UserActivityService = _exports.default = (_dec = (0, _service.inject)('ember-user-activity@scroll-activity'), _class = class UserActivityService extends _fastbootAwareEventManager.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "scrollActivity", _descriptor, this);
      _defineProperty(this, "EVENT_THROTTLE", void 0);
      _defineProperty(this, "defaultEvents", ['keydown', 'mousedown', 'scroll', 'touchstart', 'storage']);
      _defineProperty(this, "enabledEvents", (0, _array.A)());
      _defineProperty(this, "_eventsListened", (0, _array.A)());
      _defineProperty(this, "_eventSubscriberCount", {});
      _defineProperty(this, "_throttledEventHandlers", {});
      _defineProperty(this, "_boundEventHandler", null);
      _defineProperty(this, "localStorageKey", 'ember-user-activity');
    }
    // TODO: migrate to constructor
    // eslint-disable-next-line ember/classic-decorator-hooks
    init() {
      super.init(...arguments);

      // Do not throttle in testing mode
      this.EVENT_THROTTLE = _ember.default.testing ? 0 : 100;
      this._boundEventHandler = this.handleEvent.bind(this);
      this._setupListeners();
    }
    on(eventName) {
      this.enableEvent(eventName);
      if (this._eventSubscriberCount[eventName]) {
        this._eventSubscriberCount[eventName]++;
      } else {
        this._eventSubscriberCount[eventName] = 1;
      }
      return super.on(...arguments);
    }
    off(eventName) {
      if (this._eventSubscriberCount[eventName]) {
        this._eventSubscriberCount[eventName]--;
      } else {
        delete this._eventSubscriberCount[eventName];
      }
      return super.off(...arguments);
    }
    has(eventName) {
      return this._eventSubscriberCount[eventName] && this._eventSubscriberCount[eventName] > 0;
    }
    handleEvent(event) {
      if (event.type === 'storage' && event.key !== this.localStorageKey) {
        return;
      }
      (0, _runloop.throttle)(this, this._throttledEventHandlers[event.type], event, this.EVENT_THROTTLE);
    }
    _handleScroll() {
      this.handleEvent({
        type: 'scroll'
      });
    }
    _setupListeners() {
      this.defaultEvents.forEach(eventName => {
        this.enableEvent(eventName);
      });
    }
    _listen(eventName) {
      if (eventName === 'scroll') {
        this.scrollActivity.on('scroll', this, this._handleScroll);
      } else if (this._eventsListened.indexOf(eventName) === -1) {
        if (this._isFastBoot) {
          return;
        }
        this._eventsListened.pushObject(eventName);
        window.addEventListener(eventName, this._boundEventHandler, true);
      }
    }
    enableEvent(eventName) {
      if (!this.isEnabled(eventName)) {
        this.enabledEvents.pushObject(eventName);
        this._throttledEventHandlers[eventName] = function fireEnabledEvent(event) {
          if (this.isEnabled(event.type)) {
            this.fireEvent(event);
          }
        };
        this._listen(eventName);
      }
    }
    disableEvent(eventName) {
      this.enabledEvents.removeObject(eventName);
      this._eventsListened.removeObject(eventName);
      this._throttledEventHandlers[eventName] = null;
      if (eventName === 'scroll') {
        this.scrollActivity.off('scroll', this, this._handleScroll);
      } else {
        if (this._isFastBoot) {
          return;
        }
        window.removeEventListener(eventName, this._boundEventHandler, true);
      }
    }
    fireEvent(event) {
      // Only fire events that have subscribers
      if (this.has(event.type)) {
        this.trigger(event.type, event);
      }
      if (this.has('userActive')) {
        this.trigger('userActive', event);
      }
      if (this._eventsListened.includes('storage') && (0, _storageAvailable.default)('localStorage')) {
        // We store a date here since we have to update the storage with a new value
        localStorage.setItem(this.localStorageKey, new Date());
      }
    }
    isEnabled(eventName) {
      return this.enabledEvents.includes(eventName);
    }
    willDestroy() {
      while (this._eventsListened.length > 0) {
        this.disableEvent(this._eventsListened[0]);
      }
      this._eventsListened = (0, _array.A)();
      this._eventSubscriberCount = {};
      this._throttledEventHandlers = {};
      if (this.localStorageKey && (0, _storageAvailable.default)('localStorage')) {
        localStorage.removeItem(this.localStorageKey);
      }
      super.willDestroy(...arguments);
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "scrollActivity", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _class);
});